import { template as template_26d6a9991d904fa8a8e46964b557f61c } from "@ember/template-compiler";
const WelcomeHeader = template_26d6a9991d904fa8a8e46964b557f61c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
