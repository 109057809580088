import { template as template_8ca95c26fa374bcd97896e4966b9d56a } from "@ember/template-compiler";
const FKControlMenuContainer = template_8ca95c26fa374bcd97896e4966b9d56a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
