import { template as template_b5da21eb548144178822f9e23fbf7b36 } from "@ember/template-compiler";
const FKLabel = template_b5da21eb548144178822f9e23fbf7b36(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
